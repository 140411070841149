import React from 'react';

import Head from '../../components/Head';
import TodoSubList from '../../components/TodoSubList';

import '../../style/_common.scss';

class TodoPage extends React.Component {
  render() {
    const title = 'Administrative Checklist for Starting a DPC Practice';
    const desc = `Get your practice's administrative and logistical systems in place.`;
    return (
      <TodoSubList title={title} desc={desc} id="admin">
        <Head title={title} description={desc} url="/todo/admin/" image="/og_image.jpg" />
      </TodoSubList>
    );
  }
}

export default TodoPage;
